.block-hot-corners.block-container.block-inner{
    &.edit{
        margin-top:45px;
    }
    
    > .control.control-hot-corners{
        background-color: #4c5866;
        > a{
            background-color: #4c5866;
            &:hover{
                -webkit-filter: brightness(120%);
                filter: brightness(120%);
                background-color: #4c5866;
            }
        }
    }
    >.vd-border{
        background-color: #4c5866;
    }
    > .vd-hot-corners-btn{
        text-align: center;
    }
    > .block-content{
        position: fixed;
        z-index: 1002;
        top: 25%;
        left: 30%;

        width: 50%;
        min-height: 5%;
        margin: 0;

        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, .3);
        display: none;
        visibility: hidden;
        opacity:0;
        transition:opacity 0.2s linear;

        &.active{
            visibility: visible;
            opacity:1;
            display: block;
            &~.vd-hot-corners-overide{
                visibility: visible;
                opacity:0.8;
            }
        }
        &~.vd-hot-corners-overide{
            visibility: hidden;
            opacity:0;
            background-color: rgb(0, 0, 0);
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 1001;
            transition:opacity 0.2s linear;
        }
    }

}